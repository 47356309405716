import { useEffect, useState } from "react";
import { BsSearch, BsList } from "react-icons/bs";
import { NavLink } from "react-router-dom";

export const NavNalist = props => {
  const { children } = props;
  const [current, setCurrent] = useState("/")

  useEffect(() => {
    const path = window.location.pathname
    setCurrent(path)
  }, [])

  return (

    <ul className="navbar-nav">
      {/* <li className="nav-item active">
                    <a className="nav-link mr-3" href="#">
                       <BsBorderAll />
                    </a>
                </li> */}
      <li className={"nav-item"}>
        <NavLink className="nav-link mx-3" to="/">Home</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link mx-3" to="/about">About</NavLink>
      </li>
      <li className={"nav-item"}>
        <NavLink className="nav-link mx-3" to="/products">Products</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link mx-3" to="/services">Services</NavLink>
      </li>

      <li className="nav-item">
        <NavLink className="nav-link mx-3" to="/contact">Contact</NavLink>
      </li>
    </ul>


  )
}

export const NavContainer = props => {
  const { children, collapse, className } = props;
  return (
    <div className="container pt-5">
      {children}
    </div>
  )
}

// export const NavFormInline = props => {
//   return (
//     <form className="form-inline my-2 my-lg-0">
//       <button className="btn btn-light rounded-icon text-primary" type="submit"><BsSearch /></button>
//     </form>
//   )
// }

export const Navbar = props => {
  const { variant = "dark", bg = "primary", children } = props;

  return (
 <nav className="navbar navbar-expand-lg bg-primary">
  <div className="container-fluid">
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-controls="collapseExample" aria-expanded="false" aria-label="Toggle navigation">
      <span><BsList/></span>
    </button>
    <div className="collapse navbar-collapse" id="collapseExample">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
        <NavLink className="nav-link mx-3 text-white" to="/">Home</NavLink>
        </li>
        <li className="nav-item">
        <NavLink className="nav-link mx-3 text-white" to="/about">About</NavLink>
        </li>
        <li className="nav-item dropdown">
          <NavLink className="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Products
          </NavLink>
          <ul className="dropdown-menu bg-primary">
            <li><NavLink className="dropdown-item text-white" to="/products/neurology">Neurology</NavLink></li>
            <li><NavLink className="dropdown-item text-white" to="/products/neurosurgery">Neurosurgery</NavLink></li>
            <li><NavLink className="dropdown-item text-white" to="/products/neurovascular">Neurovascular</NavLink></li>
            <li><NavLink className="dropdown-item text-white" to="/products/gis">GIS</NavLink></li>
            <li><hr className="dropdown-divider"/></li>
            <li><NavLink className="dropdown-item text-white" to="/products">All Products</NavLink></li>
          </ul>
        </li>
        <li className="nav-item">
        <NavLink className="nav-link mx-3 text-white" to="/services">Services</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link mx-3 text-white" to="/contact">Contact</NavLink>
      </li>
      </ul>

    </div>
  </div>

 </nav>
  )
}

