import { Outlet } from 'react-router-dom';
import { NavContainer, NavFormInline, NavNalist, Navbar } from '../components/Navbar';
import Footer from '../components/Footer';

export const Layout = props => {
    return (
        <>
        <Navbar>
      </Navbar>

      <Outlet />
        
        <Footer />
        </>
    )
}