import { BsFacebook, BsGoogle, BsSearch, BsTiktok, BsTwitter } from "react-icons/bs";

const Footer = () => {
    return (
<div className='bg-dark text-light py-5'>
    <div className='container'>
        <div className='row'>
            <div className='col-md-8 row'>
                <div className='col'>
                    <small>
                    <p className='text-primary'>Services</p>
                    <p className='text-muted'>Neurology</p>
                    <p className='text-muted'>Neurosurgery</p>
                    <p className='text-muted'>Neurovascular</p>
                    <p className='text-muted'>GIS</p>
                    <p className='text-muted'>Other consumables</p>
                    </small>
                </div>
                <div className='col'>
                <small>
                    <p className='text-primary'>Products</p>
                    <p className='text-muted'>Neurology</p>
                    <p className='text-muted'>Neurosurgery</p>
                    <p className='text-muted'>Neurovascular</p>
                    <p className='text-muted'>GIS</p>
                    <p className='text-muted'>Other consumables</p>
                    </small>
                </div>
                <div className='col'>
                <small>
                    <p className='text-primary'>About us</p>
                    {/* <p className='text-muted'>Team members</p>
                    <p className='text-muted'>Management</p>
                    <p className='text-muted'>Accounts</p>
                    <p className='text-muted'>Sales</p> */}
                    </small>
                </div>
            </div>
            <div className='col-md-4 d-flex flex-column justify-content-between' style={{ height: 200 }}>
                <div>
                <h5 className='text-right'>Contact Angiomed</h5>
                <p className='text-right my-4'><small >&copy; 2023 Angiomed (Pty) Ltd; All rights reservered, some additional information about the angiomed policies and privacy</small></p>
                </div>



                    <div class="input-group mb-3">
                    <input type="text" class="form-control border-0 bg-dark line-only-input" placeholder="Search a product" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                    <span class="input-group-text border-0 bg-dark text-primary line-only-addon" id="basic-addon2"><BsSearch /></span>
                    </div>
            </div>
        </div>
    </div>

    <hr className='border-light my-5'/>

    <div className='container d-flex justify-content-between'>
        <div className='social'>
           <BsFacebook className='mr-2' />
           <BsGoogle className='mx-2' />
           <BsTwitter className='mx-2' />
           <BsTiktok className='mx-2' />
        </div>
        <div className='d-flex privacy col-md-6 justify-content-center'>
            <small>
           Powered By Nelotec
            </small>
        </div>
        <div className='copy'>
            <small>
                 Copyright &copy; 2023 - <span className='text-primary'>Angiomed (Pty) Ltd</span>
            </small>
        </div>
    </div>
</div>

    )
}

export default Footer;