import { NavContainer } from "../components/Navbar";
import { ProductsData, getProducts } from "../productData";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../components/HelperFunctions"
import { useState } from "react";
import { Pagination } from "../components/Pagination";
import { ProductCard } from "../components/ProductCard";
import { BsEmojiDizzy, BsChevronLeft } from "react-icons/bs";
import { placeholder } from "../assets/imagePaths";
import { Button } from '../components/Button';


export const CategorizedTypeProducts = props => {
    let { category, type } = useParams();
    const category_name = capitalizeFirstLetter(category)
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    let filteredProducts = ProductsData.filter(products => products.type === type)


    //CATEGORIZED BY TYPE EG. EEG, EMG, ETC
    if (type === "eeg" || type === "psg" || type === "emg" || type === "neuro") {
        console.log(type)
        let type_name = capitalizeFirstLetter(type)
        let totalPage = Math.ceil(filteredProducts.length / 5)
        function handlePageChange(value) {
            if (value === 'next') {
                setPage(page + 1)
            } else if (value === 'prev') {
                setPage(page - 1)
            }
            else {
                setPage(value)
            }
        }
        return (
            <div>
                <NavContainer>
                    <span className='text-muted fs-6'>Home / Products / {category_name} / {type_name}</span>
                </NavContainer>
                <div className="container pb-5">
                <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1 mt-2`} onClick={() => navigate(-1)}/>
                    <h3 className="my-3">{type_name} Products</h3>
                    {getProducts(page, filteredProducts).map(product => {
                        console.log(type_name)
                        return <ProductCard key={product.name} img={product.img} product_name={product.name} summary={product.summary} />
                    })}
                    <Pagination totalPage={totalPage} page={page} siblings={1} onPageChange={handlePageChange} />
                </div>
            </div>
        )
    } else if (type === 'shunting-system') {
        let type_name = capitalizeFirstLetter(type)
        const defit = filteredProducts.filter(defit => defit.shunt_type === 'Defit Shunt')
        const decurve = filteredProducts.filter(decurve => decurve.shunt_type === 'Decurve Shunt')
        const depus = filteredProducts.filter(depus => depus.shunt_type === "Depus Shunt")
        return (
            <>
                <NavContainer>
                    <span className='text-muted fs-6'>Home / Products / {category_name} / {type_name}</span>
                </NavContainer>
                <div className="container pb-5">
                    <h3 className="my-3">Defit Shunts</h3>
                    {/* <div class="row row-cols-1 row-cols-md-3 g-4"> */}
                    {
                        defit.map(shunt => {
                            return <ProductCard img={shunt.img} product_name={shunt.name} summary={shunt.summary} />
                        })
                    }
                    {/* </div> */}
                    <h3 className="my-3">Decurve Shunts</h3>
                    {/* <div class="row row-cols-1 row-cols-md-3 g-4"> */}
                    {
                        decurve.map(shunt => {
                            return <ProductCard img={shunt.img} product_name={shunt.name} summary={shunt.summary} />
                        })
                    }
                    {/* </div> */}
                    <h3 className="my-3">Depus Shunts</h3>
                    {/* <div class="row row-cols-1 row-cols-md-3 g-4"> */}
                    {
                        depus.map(shunt => {
                            return <ProductCard img={shunt.img} product_name={shunt.name} summary={shunt.summary} />
                        })
                    }
                    {/* </div> */}
                </div>
            </>
        )
    } else if (type === 'evd-system') {
        let type_name = capitalizeFirstLetter(type)
        const filteredProducts = ProductsData.filter(evd => evd.type === 'evd-system')
        return (
            <div>
                <NavContainer>
                    <span className='text-muted fs-6'>Home / Products / {category_name} / {type_name}</span>
                </NavContainer>
                <div className="container pb-5">
                    <h3 className="my-3">{type_name} Products</h3>
                    <div className="row g-3">
                    <div className="card mb-3 col-md" style={{ maxWidth: "540px" }}>

                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={placeholder} className="img-fluid rounded-start" alt="..." />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">External Ventricular Drainage System With Ventricular Catheter</h5>
                                    <p className="card-text">Barium Impregnated External Ventricular Drainage Catheter and Drainage System are combined to each other. System included with:</p>
                                    <div>
                                        <p>Barium Impregnated Standard Ventricular Catheter (ID 1,5 mm, OD 3,0 mm, L 31 cm) with:</p>
                                        <ul>
                                            <li>Stylet</li>
                                            <li>Fixation tab</li>
                                            <li>Luer Lock Connector and Distal Cap</li>
                                            <li>Angled Trocar</li>
                                        </ul>
                                        <p>Drainage System with:</p>
                                        <ul>
                                            <li>60 cm Reinforced Cord with Locking Mechanism</li>
                                            <li>30 cm Measuring Chart to adjust the CSF pressure (mmHg / cmH2O)</li>
                                            <li>150 ml Drip Chamber</li>
                                            <li>800 ml Drainage Bag with Air Filter</li>
                                            <li>Three Way Connector (x2)</li>
                                            <li>Hydrophobic Filter</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3 col-md" style={{ maxWidth: "540px" }}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={placeholder} className="img-fluid rounded-start" alt="..." />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">External Ventricular Drainage System With Antibiotic Impregnated Ventricular Catheter</h5>
                                    <p className="card-text">Antibiotic Impregnated External Ventricular Drainage Catheter and Drainage System are combined to each other. System included with:</p>
                                    <div>
                                        <p>Antibiotic Impregnated Ventricular Catheter (ID 1,5 mm, OD 3,0 mm, L 31 cm) with:</p>
                                        <ul>
                                            <li>Stylet</li>
                                            <li>Fixation tab</li>
                                            <li>Luer Lock Connector and Distal Cap</li>
                                            <li>Angled Trocar</li>
                                        </ul>
                                        <p>Drainage System with:</p>
                                        <ul>
                                            <li>60 cm Reinforced Cord with Locking Mechanism</li>
                                            <li>30 cm Measuring Chart to adjust the CSF pressure (mmHg / cmH2O)</li>
                                            <li>150 ml Drip Chamber</li>
                                            <li>800 ml Drainage Bag with Air Filter</li>
                                            <li>Three Way Connector (x2)</li>
                                            <li>Hydrophobic Filter</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="card mb-3 col-md" style={{ maxWidth: "540px" }}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={placeholder} className="img-fluid rounded-start" alt="..." />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">External Lumbar Drainage System With Lumbar Catheter</h5>
                                    <p className="card-text">Barium Impregnated External Lumbar Drainage Catheter and Drainage System are combined to each other. </p>
                                    <div>
                                        <p>Barium Impregnated Lumbar Catheter (ID 0,8 mm, OD 1,6 mm, L 80 cm) with:</p>
                                        <ul>
                                            <li>Guidewire</li>
                                            <li>Fixation tab</li>
                                            <li>Luer Lock Connector</li>
                                            <li>Angled Trocar</li>
                                            <li>14G Tuohy Needle</li>
                                        </ul>
                                        <p>Drainage System with:</p>
                                        <ul>
                                            <li>60 cm Reinforced Cord with Locking Mechanism</li>
                                            <li>30 cm Measuring Chart to adjust the CSF pressure (mmHg / cmH2O)</li>
                                            <li>150 ml Drip Chamber</li>
                                            <li>800 ml Drainage Bag with Air Filter</li>
                                            <li>Three Way Connector (x2)</li>
                                            <li>Hydrophobic Filter</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3 col-md" style={{ maxWidth: "540px" }}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={placeholder} className="img-fluid rounded-start" alt="..." />
                            </div>
                            <div className="col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title">External Lumbar Drainage System With Antibiotic Impregnated Lumbar Catheter</h5>
                                    <p className="card-text">Barium Impregnated External Lumbar Drainage Catheter and Drainage System are combined to each other. </p>
                                    <div>
                                        <p>Barium Impregnated Lumbar Catheter (ID 0,8 mm, OD 1,6 mm, L 80 cm) with:</p>
                                        <ul>
                                            <li>Guidewire</li>
                                            <li>Fixation tab</li>
                                            <li>Luer Lock Connector</li>
                                            <li>Angled Trocar</li>
                                            <li>14G Tuohy Needle</li>
                                        </ul>
                                        <p>Drainage System with:</p>
                                        <ul>
                                            <li>60 cm Reinforced Cord with Locking Mechanism</li>
                                            <li>30 cm Measuring Chart to adjust the CSF pressure (mmHg / cmH2O)</li>
                                            <li>150 ml Drip Chamber</li>
                                            <li>800 ml Drainage Bag with Air Filter</li>
                                            <li>Three Way Connector (x2)</li>
                                            <li>Hydrophobic Filter</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
        
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                <NavContainer>
                    <span className='text-muted fs-6'>Home / Products / {category_name}</span>
                </NavContainer>
                <div className="container pb-5">
                    <h3 className="my-3"> Products</h3>
                    <div className="text-danger">
                        <BsEmojiDizzy size={40} />
                        <span className="p-3 text-muted fs-2">Sorry, Nothing Here Yet.</span>
                    </div>
                </div>
            </div>
        )
    }
}