
import { Link } from 'react-router-dom';
import { ProductCard} from '../pages/Home';
import { BsChevronRight } from 'react-icons/bs';


const FeaturedConsumables = () => {
    return (
        <div className='container py-5 my-3'>
        <div className='row px-0 d-flex justify-content-between'>
            <h5 className='mb-3 lead col-md-5 col-12'>Featured consumables</h5>
            {/* <div className='d-flex justify-content-end col-md mb-3'>
                <span className='text-primary me-4'>Page to view more</span>
                <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1`} />
                <Button icon={<BsChevronRight />} variant={'primary'} className={`rounded-icon mx-1`} />
            </div> */}
        </div>

        <div className='row row-cols-md-4 mb-5 g-3'>
               <div class="col-md text-center">
                <ProductCard className="shadow"  variant="primary" action="/featured/lantex-surgical-staplers">
                        <p className='mb-3 text-white'>Lantex Surgical<br />Staplers</p>
                        <small className='mb-4 text-white'>"Empowering precision and efficiency in wound closure - Our exclusive partnership with Lantex Surgical Staplers brings cutting-edge technology to elevate surgical outcomes."</small>
                  <span>Read More</span>
                </ProductCard>
                </div>
                <div class="col-md">
                <ProductCard className="shadow"  variant="white" action="/featured/foryou-npwt">
                        <p className='mb-3 text-dark'>FORYOU-NPWT</p>
                        <small className='mb-4 text-dark'>"Revolutionize wound treatment - Explore our exceptional offer of placement and sales for the Foryou NPWT machine, advancing patient care and healing outcomes."</small>
                        <span>Read More</span>
                </ProductCard>
                </div>
                <div class="col-md">
                <ProductCard className="shadow"  variant="white" action="/featured/rentals">
                        <p className='mb-3 text-dark'>EEG/EMG Rentals</p>
                        <small className='mb-4 text-dark'>"Unlock advanced diagnostics with our rental services, offering Deymed TruScan EEG, TruTrace Traveller EMG, and SomniPro PSG systems for comprehensive medical assessments."</small>
                        <span>Read More</span>
                </ProductCard>
                </div>
                <div class="col-md">
                <ProductCard className="shadow"  variant="white" action="/featured/ultrasonic-surgical-system" >
                        <p className='mb-3 text-dark'>Innolcon - Ultrasonic Surgical System</p>
                        <small className='mb-4 text-dark'>"Empowering hospitals with seamless access to ultrasonic surgical technology on a contractual basis, relieving them of maintenance responsibilities while focusing on Scalpel purchases for enhanced patient care."</small>
                </ProductCard>
                </div>
                <div class="col-md">
                <ProductCard className="shadow"  variant="white" action="/featured/posts" >
                        <p className='mb-3 text-dark'>Available Postions</p>
                        <small className='mb-4 text-dark'>"Exciting job opportunities await! Join our team and be a part of our mission to revolutionize the industry with cutting-edge innovation and impactful solutions."</small>
                </ProductCard>
                </div>
        </div>   
</div>

    )
} 


export default FeaturedConsumables;