import { GiBrain } from "react-icons/gi";
import { NavContainer, Navbar } from "../components/Navbar"
import { ProductSummary } from "./Home";
import { TbCircles, TbHeartRateMonitor, TbLungs } from "react-icons/tb";
import TopCover from '../components/TopCover'
import { splash } from "../assets/imagePaths";
import neuro from '../assets/imgs/neuro.jpeg'
import neurosurgery from '../assets/imgs/neurosurgery-2108.jpg'
import neurovascular from '../assets/imgs/neurovascular.webp'
import gis from '../assets/imgs/gis.jpeg'
import { Button } from '../components/Button';
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Services = props => {
  const navigate = useNavigate();
  return (
    <div>
   <TopCover splash={splash} title="Services" subtitle="HOME | SERVICES"/>
      <NavContainer>
        <span className='text-muted fs-6'>Home / Services</span>
      </NavContainer>

      <div className="container">
      <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1 mt-2`} onClick={() => navigate(-1)}/>
        <h3 className="my-5">Services</h3>


        <div className='row g-3 pb-5 mb-3'>
          <div className="col-md-6">
            <div className="card">
              <div class="row g-0">
                <div class="col-md-6">
                  <ProductSummary align="start" flex="column" icon={<GiBrain className="ms-4" />} name="Neurology" title="Neurology products" />
                  <p className="ms-4 my-3"> Angiomed offers a comprehensive range of neurology products designed to address various neurological conditions and improve patient outcomes.</p>
                </div>
                <div class="col-md-6 d-flex align-items-center" style={{ overflowY: 'hidden', height: 220 }}>
                  <img src={neuro} class="img-fluid rounded-end" alt="..." style={{ borderTopLeftRadius: 125, borderBottomLeftRadius: 125, height: 250 }} />
                </div>
              </div>

            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="h5 my-3">Neurology services</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div class="row g-0">
                <div class="col-md-6">
                  <ProductSummary align="start" flex="column" icon={<TbHeartRateMonitor className="ms-4" />} name="Neurosurgery" title="Neurosurgery products" />
                  <p className="ms-4 my-3">Angiomed's neurosurgery products provide cutting-edge solutions to support surgeons in performing intricate procedures and delivering optimal patient care.</p>
                </div>

                <div class="col-md-6 d-flex align-items-center" style={{ overflowY: 'hidden', height: 220 }}>
                  <img src={neurosurgery} class="img-fluid rounded-end" alt="..." style={{ borderTopLeftRadius: 125, borderBottomLeftRadius: 125, height: 250 }} />
                </div>
              </div>
            </div>
            <p className="h5 my-3">Neurosurgery services</p>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div class="row g-0">
                <div class="col-md-6">
                  <ProductSummary align="start" flex="column" icon={<TbLungs className="ms-4" />} name="Neurovascular" title="Neurovascular products" />            
                  <p className="ms-4 my-3">Angiomed's neurovascular products offer advanced technologies and innovative devices for the treatment and management of complex vascular conditions in the neurology field.</p>
                </div>

                <div class="col-md-6 d-flex align-items-center" style={{ overflowY: 'hidden', height: 220 }}>
                  <img src={neurovascular} class="img-fluid rounded-end" alt="..." style={{ borderTopLeftRadius: 125, borderBottomLeftRadius: 125, height: 250 }} />
                </div>
              </div>
            </div>
            <p className="h5 my-3">Neurovascular services</p>
          </div>

          <div className="col-md-6">
            <div className="card">
              <div class="row g-0">
                <div class="col-md-6">
                  <ProductSummary align="start" flex="column" icon={<TbCircles className="ms-4" />} name="GIS" title="Products and services" />                    <p className="ms-4 my-3">Some info about the product</p>
                  <p className="ms-4 my-3">Angiomed's GIS products provide sophisticated Geographic Information System solutions tailored for neurology research and clinical applications.</p>

                </div>

                <div class="col-md-6 d-flex align-items-center" style={{ overflowY: 'hidden', height: 220 }}>
                  <img src={gis} class="img-fluid rounded-end" alt="..." style={{ borderTopLeftRadius: 125, borderBottomLeftRadius: 125, height: 250 }} />
                </div>
              </div>
            </div>

            <p className="h5 my-3">GIS services</p>
          </div>


        </div>

      </div>

    </div>
  )
}

export default Services;