import {BsArrowsAngleExpand} from "react-icons/bs";
import Modal from "./Modal";
import { useState } from "react";
 
 
 
 export const ProductCard = ({ img, product_name, summary }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    return (

         <div className="product-card card card-body shadow-sm my-3">
      <div className="row">
        <div className="col-md-2 position-relative product-img">
          <img src={img} className="img-fluid card-img-top rounded-end" alt="..." />
          <button
            className="btn btn-outline-primary rounded-icon text-primary position-absolute top-0 end-0 m-2 d-md-none"
            type="button"
            onClick={openModal}
          >
            <BsArrowsAngleExpand />
          </button>
        </div>
        <div className="col-7 d-flex flex-column justify-content-center text-center">
          <p className="fw-normal fs-6">{product_name}</p>
          <p className="text-muted">{summary}</p>
        </div>
        <div className="col-3 d-flex justify-content-between flex-column align-items-end">
          <button className="btn btn-outline-primary rounded-icon text-primary d-none d-md-block" type="button" onClick={openModal}>
            <BsArrowsAngleExpand />
          </button>
        </div>
      </div>
      {isModalOpen && <Modal img={img} onClose={closeModal} />} {/* Render the Modal component when isModalOpen is true */}
    </div>
    )
}