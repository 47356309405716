import TopCover from '../components/TopCover'
import { placeholder, splash } from "../assets/imagePaths"
import { NavContainer } from '../components/Navbar'
import { useParams } from 'react-router-dom'
import { Button } from '../components/Button';
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export const Featured = (props) => {

    return (

        <div>
            <TopCover splash={splash} title="Featured" subtitle="HOME > FEATURED" />
            <NavContainer>
                <span className='text-muted fs-6'>Home / Featured</span>
            </NavContainer>
            <div className='container'>
           
                <Rental />
            </div>
        </div>
    )
}

export const FullFeatured = () => {

    const navigate = useNavigate();
    let { article } = useParams();
    let component = null
    if (article === 'lantex-surgical-staplers') {
        component = <LantexSurgicalStaplers />
    } else if (article === 'foryou-npwt') {
        component = <ForYouNPWT />
    } else if (article === 'rentals') {
        component = <Rental />
    } else if(article === 'ultrasonic-surgical-system'){
      component = <UltrasonicSurgicalSystem/>
    } else if (article === 'posts'){
        component = <Posts/>
    } 
    else {
        return (
            <div>Sorry Nothing Here</div>
        )
    }


    return (
        <div>
            <TopCover splash={splash} title="Featured" subtitle="HOME | FEATURED" />
            <NavContainer>
                <span className='text-muted fs-6'>Home / Featured</span>
            </NavContainer>
            <div className='container'>
            <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1 mt-2 mb-2`} onClick={() => navigate(-1)}/>
               {component}
            </div>
        </div>
    )
}

const LantexSurgicalStaplers = () => {
    return (
        <div className="card mb-3">

            <div class="card-body">
                <h5 class="card-title">Lantex Surgical Staplers</h5>
                <div className='text-center mb-3'>
                    <img src={placeholder} class="img-thumbnail mx-auto" alt="..." />
                </div>
                <p class="card-text">We are pleased to announce our recent partnership with Lantex Medical Technologies, a renowned name in the medical device industry. This collaboration marks a significant milestone for our company as we venture into providing advanced medical solutions to healthcare professionals and facilities.</p>
                <h6 className='card-subtitle mb-2'>About Lantex Medical Technologies:</h6>
                <p className='card-text'>Lantex Medical Technologies is a leading global manufacturer and supplier of cutting-edge medical devices and technologies. With a strong commitment to innovation and quality, Lantex has earned a reputation for delivering reliable and high-performance products that enhance patient care and surgical outcomes.</p>
                <h6 className='card-subtitle mb-2'>Product Offering: Surgical Staplers and More</h6>
                <p className='card-text'>As part of our partnership with Lantex Medical Technologies, we are now offering a comprehensive range of surgical staplers and other relevant products to medical institutions and professionals. These products are designed to meet the diverse needs of surgical procedures and improve overall surgical efficiency.</p>
                <ul>
                    <li><b>Endoscopic Linear Cutter Stapler and Reloads:</b> Lantex's Endoscopic Linear Cutter Stapler and Reloads are essential tools for minimally invasive surgeries. These instruments provide precise and secure closure of tissue during procedures, reducing the risk of complications and promoting faster patient recovery.</li>
                    <li><b>Linear Cutter Stapler and Reloads:</b> Lantex's Linear Cutter Stapler and Reloads are designed for open surgical procedures. They offer reliable and consistent staple formation, ensuring secure wound closure and optimal tissue approximation.</li>
                    <li><b>Hemorrhoidal Stapler:</b> Lantex's Hemorrhoidal Stapler is specifically designed for the treatment of hemorrhoids. This stapler simplifies the procedure and minimizes patient discomfort, making it an invaluable tool for proctologists and surgeons specializing in colorectal surgery.</li>
                    <li><b>Circular Staplers:</b> Lantex's Circular Staplers are instrumental in gastrointestinal surgeries, facilitating the creation of circular anastomoses. These staplers provide a safe and efficient method for joining sections of the digestive tract during surgical interventions.</li>
                </ul>
                <h6 className='card-subtitle mb-2'>Ensuring Quality and Patient Safety:</h6>
                <p className='card-text'>As a supplier of Lantex Medical Technologies' products, we are committed to upholding the highest standards of quality and patient safety. All products undergo rigorous quality control measures and adhere to relevant industry regulations and standards.</p>
                <p className='card-text'>Our partnership with Lantex Medical Technologies allows us to provide state-of-the-art surgical staplers and relevant products to medical professionals, enabling them to perform surgical procedures with confidence and precision. We are excited to contribute to improved patient outcomes and support healthcare facilities in their pursuit of excellence.</p>
                <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p>
            </div>
        </div>
    )
}

const ForYouNPWT = () => {
    return (

        <div className='card mb-3'>
            <div className='card-body'>
                <h5 class="card-title">ForYou NPWT</h5>
                <div className='text-center mb-3'>
                    <img src={placeholder} class="img-thumbnail mx-auto" alt="..." />
                </div>

                <h6 className='card-subtitle mb-2'>Introducing the Foryou NPWT Machine for Advanced Wound Treatment</h6>
                <p className='card-text'>In a significant step towards enhancing patient care and wound management, we are delighted to announce that we have expanded our product offerings to include the state-of-the-art Foryou Negative Pressure Wound Therapy (NPWT) machine. This cutting-edge medical device is designed to revolutionize the treatment of wounds, providing healthcare professionals with a powerful tool to promote faster healing and improved patient outcomes.</p>
                <p className='card-text'>The Foryou NPWT machine is a highly advanced medical device that utilizes negative pressure therapy to accelerate wound healing and manage complex wounds. NPWT is a well-established and evidence-based treatment technique that has shown remarkable efficacy in a variety of clinical settings.</p>
                <h6 className='card-subtitle'>Key Features and Benefits:</h6>
                <ul className='pt-3'>
                    <li><b>Precision Wound Management:</b> The Foryou NPWT machine offers precise and controlled delivery of negative pressure to the wound site. This helps in reducing excess fluid, promoting granulation tissue formation, and facilitating wound closure.</li>
                    <li><b>Versatile Application:</b> The device is suitable for managing a wide range of wounds, including acute, chronic, traumatic, and surgical wounds. It can also be utilized for managing pressure ulcers, diabetic foot ulcers, and dehisced surgical incisions.</li>
                    <li><b>Enhanced Healing:</b> NPWT has been proven to accelerate wound healing by creating a conducive environment for tissue regeneration. It can significantly reduce healing time, leading to shorter hospital stays and improved patient comfort.</li>
                    <li><b>Reduced Infection Risk:</b> By removing exudate and debris from the wound, the Foryou NPWT machine helps reduce the risk of infection and promotes a clean wound healing process.</li>
                    <li><b>Easy-to-Use Design:</b> The device features an intuitive interface and user-friendly controls, making it simple for healthcare professionals to operate and adjust the settings as per individual patient needs.</li>
                    <li><b>Portable and Ambulatory:</b> The Foryou NPWT machine comes with a compact and lightweight design, allowing for ease of transportation and ambulatory use. This portability enables patients to receive continuous wound therapy while maintaining their daily routines.</li>
                </ul>
                <h6 className='card-subtitle'>Contact Us for Further Information:</h6>
                <p className='card-text mt-3'>For further details on the Foryou NPWT machine and how it can benefit your medical practice or institution, we encourage interested parties to reach out to us. Our knowledgeable representatives are available to provide comprehensive information, demonstrate the device's functionalities, and discuss tailored solutions to meet specific clinical needs.</p>

            </div>
        </div>
    )
}

const Rental = () => {
    return (

        <div className='card mb-3'>
            <div className='card-body'>
                <h5 className='card-title'><b>DeyMed EEG/EMG Systems Rental</b></h5>
                <div class="card mb-3 text-center mx-auto border-light" style={{ maxWidth: "700px" }}>
                    <div class="row g-0">
                        <div class="col-md-4 my-auto">
                            <img src={placeholder} class="img-fluid rounded-start img-thumbnail" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Deymed TruScan EEG Systems</h5>
                                <p class="card-text">Step into the future of neurological diagnostics with our Deymed TruScan EEG systems. These top-of-the-line machines offer unparalleled precision and reliability in capturing and analyzing brainwave activity. As an essential tool for neurologists and healthcare professionals, the TruScan EEG systems provide real-time insights into brain functioning, enabling you to diagnose and monitor a broad range of neurological conditions with confidence. Whether it's seizure detection, sleep disorders, or brain trauma evaluation, the TruScan EEG systems have got you covered.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-0">
                        <div class="col-md-4 my-auto">
                            <img src={placeholder} class="img-fluid rounded-start img-thumbnail" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Deymed TruTrace Traveller EMG Systems</h5>
                                <p class="card-text">Empower your electromyography (EMG) studies with the extraordinary Deymed TruTrace Traveller EMG systems. Unleashing the full potential of muscle and nerve diagnostics, these portable and user-friendly systems set a new standard in EMG testing. With advanced signal processing algorithms and superior signal-to-noise ratio, the TruTrace Traveller ensures accurate and precise EMG assessments, making it an invaluable asset in your neuromuscular examinations. Seamlessly perform nerve conduction studies and assess neuromuscular junction disorders, all in the palm of your hand.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row g-0">
                        <div class="col-md-4 my-auto">
                            <img src={placeholder} class="img-fluid rounded-start img-thumbnail" alt="..." />
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Deymed SomniPro PSG Systems</h5>
                                <p class="card-text">Discover the pinnacle of sleep diagnostics with our Deymed SomniPro PSG systems. Designed to unravel the mysteries of sleep disorders, this comprehensive polysomnography system provides a holistic view of patients' sleep patterns and behaviors. From sleep apnea and insomnia to periodic limb movements, the SomniPro PSG systems deliver exhaustive data that enables you to tailor personalized treatment plans. Embrace a restful night's sleep for your patients with unmatched precision and ease.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='card-subtitle'><b>Why Choose Our Rental Services?</b></div>
                <p className='card-text'>Our rental services open the doors to a world of possibilities for your medical practice. By opting for our flexible and cost-effective rental plans, you unlock an entire spectrum of cutting-edge technologies without the burden of upfront investments. We ensure that every piece of equipment is meticulously maintained and calibrated, guaranteeing optimal performance and accuracy throughout the rental period.

                    Our team of dedicated professionals stands ready to provide comprehensive training and support, ensuring a seamless integration of these systems into your clinical workflow. From installation to troubleshooting, we've got you covered at every step of the journey.</p>

            </div>
        </div>

    )
}

const Posts = () => {
    return (
    <div className='card mb-3'>
        <div className='card-body'>
        <h5 className='card-title'><b>Join our Dynamic Team - Exciting Job Opportunities Await!</b></h5>
        <p className='card-text'>Are you ready to embark on a rewarding and fulfilling career in the medical field? Look no further! At Angiomed, we take pride in our commitment to excellence and innovation, and we are thrilled to announce that we have several open positions within our organization. Join our dynamic team and become part of a company that is dedicated to making a positive impact in the healthcare industry.</p>
        <h6 className='card-subtitle'>1. Medical Sales Representatives:</h6>
        <p className='card-text'>As a Medical Sales Representative at Angiomed, you will play a crucial role in driving the success of our products and services. Your passion for healthcare and strong communication skills will enable you to establish and maintain strong relationships with healthcare professionals and institutions. As a representative of Angiomed, you will be responsible for promoting our cutting-edge medical technologies and solutions, providing valuable insights to clients, and ensuring that our products meet the needs of patients and medical practitioners alike.</p>
        <h6 className='card-subtitle'>Key Responsibilities:</h6>
        <ul>
           <li>Promote and sell our range of innovative medical products to healthcare facilities and professionals</li>
           <li>Conduct product demonstrations and training sessions to showcase the benefits and applications of our technologies.</li>
           <li>Build and maintain positive relationships with existing clients and explore opportunities for new business development.</li>
           <li>Stay up-to-date with industry trends and competitor activities to provide strategic insights to the sales team</li>
           <li>Collaborate with the marketing team to develop effective sales strategies and marketing campaigns.</li>
        </ul>
        <h6 className='card-subtitle'>2. Administrator:</h6>
        <p className='card-text'>As an Administrator at Angiomed, you will be the backbone of our operations, providing essential support to various departments and ensuring smooth day-to-day functioning. Your organizational skills and attention to detail will be invaluable in managing administrative tasks efficiently. From coordinating meetings and handling correspondence to managing databases and organizing company events, you will be an essential part of our team, contributing to the overall success of our organization.</p>
        <h6 className='card-subtitle'>Key Responsibilities:</h6>
        <ul>
           <li>Manage and coordinate administrative tasks, including scheduling appointments, managing files, and handling correspondence.</li>
           <li>Maintain and update databases, ensuring accurate and up-to-date information is readily accessible.</li>
           <li>Support the finance team with basic bookkeeping and invoicing tasks.</li>
           <li>Assist in organizing company events, workshops, and conferences.</li>
           <li>Provide general office support, including answering phone calls and directing inquiries.</li>
        </ul>

        <h5 className='card-subtitle'>How To Apply:</h5>
        <p className='card-text'>To apply for any of these exciting positions, please send your resume to <b>info@angiomed.co.za.</b> Be sure to include a cover letter outlining your interest in the position and how your skills and experiences align with our organization's values and mission.</p>
        
        </div>
    </div> 
    )
}

const UltrasonicSurgicalSystem = () => {
    return (
        <div className='card mb-3'>
            <div className='card-body'>
            <div className='text-center mb-3'>
                    <img src={placeholder} class="img-thumbnail mx-auto" alt="..." />
                </div>
            <h5 className='card-title'><b>Revolutionizing Surgical Technology - Ultrasonic Surgical System Placement</b></h5>
            <p className='card-text'>Are you ready to take your hospital's surgical capabilities to the next level? Look no further! We are excited to introduce our exclusive Ultrasonic Surgical System Placement program, designed to enhance your hospital's surgical precision, efficiency, and patient outcomes like never before.</p>
            <h6 className='card-subtitle'>The Ultimate Surgical Advancement: Ultrasonic Surgical System</h6>
            <p className='card-text'>The Ultrasonic Surgical System (Ultrasonic Scalpel) is a groundbreaking piece of clinical surgical equipment that uses ultrasonic energy to promote hemostatic cutting and coagulation of soft tissues. It can be used to cut soft tissue other than bone tissue and fallopian tubes, as well as to cut and seal vessels up to 5mm in diameter. It has been widely applied in a variety of open and endoscopic surgeries. Compared with traditional manual devices or devices powered by other energy sources, the Ultrasonic Scalpel offers greater accuracy, lower smoke output, and no electro current flows through patients bodies - bringing greater safety to patients and more convenience to surgeons.</p>
            <p className='card-text'>At the forefront of medical innovation, our Ultrasonic Surgical System is a cutting-edge technology that harnesses the power of ultrasonic energy to revolutionize surgical procedures. This advanced system offers unparalleled benefits, including precise tissue dissection, reduced bleeding, minimal collateral damage, and faster healing for patients.</p>
            <h6 className='card-subtitle'>Flexible Contractual Basis Placement</h6>
            <p className='card-text'>Our Ultrasonic Surgical System is available for placement at your esteemed hospital on a contractual basis. The flexibility of this arrangement empowers your surgical team to access state-of-the-art technology without the burden of upfront investments. Say goodbye to prohibitive capital costs and embrace a seamless integration of the Ultrasonic Surgical System into your surgical department.</p>
            <h6 className='card-subtitle'>Seamless and Hassle-Free Service</h6>
            <p className='card-text'>One of the standout features of our placement program is that your hospital will not be responsible for any services to the system. Our team of dedicated technical experts will handle all aspects of system maintenance, troubleshooting, and support, ensuring seamless functionality throughout the contract term.</p>
            <h6 className='card-subtitle'>Focus on Scalpel Purchases</h6>
            <p className='card-text'>During the contract term, your hospital's sole responsibility will be to purchase Scalpels, the only consumable item associated with the Ultrasonic Surgical System. We offer a comprehensive range of high-quality Scalpels, optimized to complement the system's performance, ensuring smooth surgical procedures and exceptional patient outcomes.</p>
            <p className='card-text'><a href='/contact'>Contact us</a> for further information.</p>
            </div>
        </div>
    )
}


