import TopCover from '../components/TopCover'
import splash from '../assets/imgs/blog_1.jpeg'
import FeaturedConsumables from '../components/FeaturedConsumables';
import { BsArrowRight, BsChevronLeft, BsChevronRight, BsDownload, BsFacebook, BsFilePdf, BsGoogle, BsHeartPulse, BsSearch, BsTiktok, BsTwitter } from 'react-icons/bs';
import { GiBrain } from "react-icons/gi";
import { TbLungs, TbHeartRateMonitor, TbCircles } from "react-icons/tb";
import { Button } from '../components/Button';

import { ProductSummary } from './Home';
import { useNavigate } from 'react-router-dom';
const PDF_COMPANY_PROFILE = 'http://localhost:3000/assets/pdf/AngiomedCompanyProfile.pdf'
const PDF_PRODUCT_BROCHURE = 'http://localhost:3000/assets/pdf/GASTROINTESTINAL_SURGERY.pdf'
const About = () => {

    const navigate = useNavigate();
    function pdfDownload(name) {
        const link = document.createElement('a');
        link.href = name
        link.setAttribute('download', name)
        link.click();
        link.remove();

    }


    return (
        <div>
            <TopCover splash={splash} title="About us" subtitle="HOME | ABOUT US" />


            <div className='container pb-5 mb-3'>
            <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1 mt-2`} onClick={() => navigate(-1)}/>
                <FeaturedConsumables />
                <h3 className='lead my-5'>About Angiomed</h3>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <p>
                            We are a South African company with over 8 years experience
                            in the distribution and supply of medical and surgical devices/ equipment.</p><p>
                            Founded in 2014 in Pretoria, our company is registered with the South African
                            Health Products Regulatory Authority, SAHPRA.</p><p> Our company has grown from
                                being a Provincial level distributor to a national level distributor with
                                over 230 hospitals in our client portfolio from both the Public and Private
                                Healthcare sectors and we are looking to grow our distribution to neighbouring
                                SADC countries.
                        </p>
                    </div>
                    <div className='col-12 col-md-6'>
                        <p>
                            We are a 100% black owned company with a level 1 BBBEE status that offers services to the healthcare sector. Angiomed Medical prides itself with being able to offer great services to customers on a larger scale and have a dedicated team that has the experience to answer to every customers need. We currently based in Pretoria but we offer our service countrywide and over our boarders.
                        </p><p>
                            To promote better health and healing for patients through new and advanced technologies and provide healthcare solutions that are simple and efficient for healthcare professionals.
                        </p><p>
                            To position ourselves as the leading provider of Specialized healthcare services and devices to ever challenging healthcare conditions in Africa.
                        </p>

                    </div>
                    <div className='col-12 col-md-6 d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column text-primary'>

                            <BsFilePdf size={36} onClick={() => pdfDownload(PDF_COMPANY_PROFILE)} />
                            <p className='py-3'>Download<br />Company profile</p>

                        </div>

                        <div className='d-flex flex-column text-dark'>
                            <BsFilePdf size={36} onClick={() => pdfDownload(PDF_PRODUCT_BROCHURE)}/>
                            <p className='py-3'>Download<br />product brochure</p>
                        </div>
                    </div>
                </div>

                <div className='row d-flex justify-content-between py-5 my-3'>
                    <div className='col-md'>
                        <ProductSummary icon={<GiBrain />} name="Neurology" title="Neurology products" />
                    </div>
                    <div className='col-md'>
                        <ProductSummary icon={<GiBrain />} name="Neurosurgery" title="Neurosurgery products" />
                    </div>
                    <div className='col-md'>
                        <ProductSummary icon={<GiBrain />} name="Neurovascular" title="Neurosurgery products" />
                    </div>

                    <div className='col-md'>
                        <ProductSummary icon={<TbLungs />} name="GIS" title="Products and services" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;