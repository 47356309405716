import { useState } from 'react';

const Modal = ({ img, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button type="button" className="btn-close" onClick={onClose}>
          &times;
        </button>
        <img src={img} alt="Product" className="img-fluid" />
      </div>
    </div>
  );
};

export default Modal;
