import {
  BsArrowRight,
  BsChevronLeft,
  BsChevronRight,
  BsDownload,
  BsFacebook,
  BsFilePdf,
  BsGoogle,
  BsHeartPulse,
  BsSearch,
  BsTiktok,
  BsTwitter,
} from "react-icons/bs";
import { GiBrain } from "react-icons/gi";
import { TbLungs, TbHeartRateMonitor, TbCircles } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  splash,
  angiomed003,
  angiomed004,
  angiomed008,
  emg003,
  emg004,
  emg005,
  emg006,
  psg001,
  neuro001,
  neuro008,
  evo003,
} from "../assets/imagePaths";

import { Button } from "../components/Button";
import Footer from "../components/Footer";
import FeaturedConsumables from "../components/FeaturedConsumables";

export const ProductSummary = (props) => {
  const { icon, name, title, flex = "row", align = "center" } = props;
  return (
    // <div className={`d-flex justify-content-start align-items-${align} flex-${flex}`}>
      <div className="d-flex align-items-center">
      <span className="text-danger" style={{ fontSize: 38 }}>
        {icon}
      </span>
      <span className="pl-4">
        <div>{name}</div>
        <div>{title}</div>
      </span>
    </div>
  );
};

export const ProductCard = (props) => {
  const {
    img,
    long,
    tall,
    children,
    variant,
    overlay,
    action,
    callToaction,
    className,
  } = props;
  const navigate = useNavigate();
  if (long)
    return (
      <div className={`card bg-${variant} border-0 ${className}`}>
        <div class="row g-0 card-body">
          {overlay && (
            <div
              className={`card-img-overlay bg-${overlay}`}
              style={{ opacity: 0.9 }}
            ></div>
          )}
          <div class="col-md-5 d-flex flex-column">{children}</div>
          <div class="col-md-7">
            {img && <img src={img} class="img-fluid rounded-end" alt="..." />}
            <div className="card-img-overlay d-flex align-items-end justify-content-end">
              {action && (
                <Button
                  icon={<BsArrowRight />}
                  variant={overlay ? "light" : "primary"}
                  className="rounded-icon p-y"
                  onClick={() => navigate(action)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  else if (tall)
    return (
      <div className={`card bg-${variant} border-0 h-100 ${className}`}>
        <div class="row g-0 card-body">
          <div class="col-md-12">
            {img && (
              <img src={img} class="img-fluid rounded-bottom" alt="..." />
            )}
          </div>
          {overlay && (
            <div
              className={`card-img-overlay bg-${overlay}`}
              style={{ opacity: 0.9 }}
            ></div>
          )}
          <div className="card-img-overlay d-flex justify-content-end">
            {callToaction}
          </div>
          <div class="col-md-12 d-flex flex-column">{children}</div>
          <div className="card-img-overlay d-flex align-items-end justify-content-end">
            {action && (
              <Button
                icon={<BsArrowRight />}
                variant={overlay ? "light" : "primary"}
                className={`rounded-icon p-y`}
                onClick={() => navigate(action)}
              />
            )}
          </div>
        </div>
      </div>
    );
  else
    return (
      <div
        className={`card bg-${variant} border-0 ${className}`}
        style={{ height: 225 }}
      >
        {img && <img src={img} class="img-fluid rounded" alt="..." />}
        <div class="card-body">
          {overlay && (
            <div
              className={`card-img-overlay bg-${overlay}`}
              style={{ opacity: 0.9 }}
            ></div>
          )}
          <div className="card-img-overlay d-flex flex-column h-100">
            {children}
          </div>
          <div className="card-img-overlay d-flex align-items-end justify-content-end">
            {action && (
              <Button
                icon={<BsArrowRight />}
                variant={overlay ? "light" : "primary"}
                className="rounded-icon p-y"
                onClick={() => navigate(action)}
              />
            )}
          </div>
        </div>
      </div>
    );
};

export const Home = (props) => {
  return (
    <>
      <div
        className="card text-bg-dark rounded-0 border-0 justify-content-center"
        style={{ height:"19rem", overflow: "hidden" }}
      >
        <img src={splash} className="card-img" alt="..." />
        <div class="card-img-overlay bg-dark op-1"></div>
        <div class="card-img-overlay">
          <div className="h-100 container d-flex flex-column justify-content-around">
          <div className="row">
            <h5 class="text-center display-6 pt-5 col-sm-12">
              Pioneering Minimally Invasive Surgery
            </h5>
   
                <div className="col-sm-6 col-md-3 pt-0 pt-sm-3 col-6">
                  <ProductSummary icon={<GiBrain />} name="Neurology" title="" />
                  </div>
<div className="col-sm-6 col-md-3 pt-0 pt-sm-3 col-6">
                  <ProductSummary icon={<GiBrain />} name="Neurosurgery" title="" />
                  </div>
<div className="col-sm-6 col-md-3 pt-sm-3 pt-0 col-6">
                  <ProductSummary
                    icon={<GiBrain />}
                    name="Neurovascular"
                    title=""
                  />
                  </div>
<div className="col-sm-6 col-md-3 pt-sm-3 pt-0 col-6">
                  <ProductSummary icon={<TbLungs />} name="GIS" title="" />
                  </div>
              </div>
              </div>
              </div>

          </div>
  


      <div className="container py-5 mb-3 ">
        <div className=" row px-0 d-flex justify-content-between ">
          <h5 className="mb-3 lead col-12 col-md-5 ">Top selling products</h5>
          {/* <div className="col-7 mb-4 mb-md-0">
            <span className="text-primary me-4">Showing total</span>
            <span className="text-muted">6 of 23</span>
          </div> */}
        </div>
        <div className="row ">
          <div className="row col-md ">
            <div class="col-12 mb-4 ">
              <ProductCard
                img={angiomed008}
                long
                variant="success"
                action="/products/neurology/eeg"
              >
                <p className="mb-1">
                  EEG
                  <br />
                  Systems
                </p>
                <span className="text-muted">Some information</span>
              </ProductCard>
            </div>
            <div class="col-md mb-4 ">
              <ProductCard
                img={emg006}
                variant="dark"
                action="/products/neurology/emg"
              >
                <p className="mb-1 text-light">
                  EMG
                  <br />
                  Systems
                </p>
                <h3 className="mt-auto text-primary">
                  <small className="small"></small>
                </h3>
              </ProductCard>
            </div>
            <div class="col-md mb-4 ">
              <ProductCard
                img={psg001}
                variant="warning"
                action="/products/neurology/psg"
              >
                <p className="mb-1">
                  PSG
                  <br />
                  Systems
                </p>
                <h3 className="mt-auto">
                  <small className="small"></small>
                </h3>
              </ProductCard>
            </div>
          </div>

          <div className="row col-md ">
            <div class="col-md">
              <ProductCard
                img={neuro008}
                tall
                variant="dark"
                action="/products/neurology/neuro"
              >
                <p className="mb-1 text-white">
                  Neurosurgery
                  <br />
                  Drainage System
                </p>
                <span className="text-muted"></span>
                <h3 className="mt-auto">
                  <small className="small"></small>
                </h3>
              </ProductCard>
            </div>
            <div class="col-md d-flex justify-content-between flex-column ">
              <div className="mt-3 mb-3">
              <ProductCard
                img={neuro001}
                variant="success"
                action="/products/neurosurgery/shunting-system"
              >
                <p className="mt-5 mb-1 text-white"></p>
                <p className="mt-auto">
                  Neurosurgery
                  <br />
                  Shunting systems<small className="small"></small>
                </p>
              </ProductCard>
              </div>
              <div className="mb-3">
              <ProductCard
                img={evo003}
                variant="black"
                action="/products/neurosurgery/drill-systems"
              >
                <p className="mb-1">
                  <br />
                </p>
                <p className="mt-auto text-white">
                  Neurosurgery
                  <br />
                  Drill systems<small className="small"></small>
                </p>
              </ProductCard>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row bg-light ">
        <div className="container py-5 mb-3">
          <div className="row px-0 d-flex justify-content-between">
            <h5 className="mb-3 lead col-md-5 col-12">Featured products</h5>
            {/* <div className="d-flex col-md mb-3">
              <span className="text-primary me-4">Page to view more</span>
              <Button
                icon={<BsChevronLeft />}
                variant={"outline-secondary"}
                className={`rounded-icon mx-1`}
              />
              <Button
                icon={<BsChevronRight />}
                variant={"primary"}
                className={`rounded-icon mx-1`}
              />
            </div> */}
          </div>

          <div className="row row-cols-md-4 g-3 mb-5">
            <div className="col-md">
              <ProductCard
                className="shadow-sm"
                img={angiomed003}
                tall
                variant="white"
                action="/products/eeg"
              >
                <p className="mb-1">
                  Neurophysiology
                  <br />
                  Deymed
                </p>
                <span className="text-muted">FlexiCart LTM</span>
                <h3 className="mt-auto">
                  <small className="small"></small>
                </h3>
              </ProductCard>
            </div>
            <div className="col-md">
              <ProductCard
                className="shadow-sm"
                img={angiomed004}
                tall
                variant="white"
                action
              >
                <p className="mb-1 mt-auto">
                  <br />
                  <br />
                  <br />
                  <br></br>Neurophysiology
                  <br />
                  Deymed
                </p>
                <span className="text-muted">Portable</span>
                <h3 className="mt-auto">
                  <small className="small"></small>
                </h3>
              </ProductCard>
            </div>
            <div className="col-md">
              <ProductCard
                className="shadow-sm"
                img={emg003}
                tall
                variant="white"
                action
              >
                <p className="mb-1">
                  Neurophysiology
                  <br />
                  Deymed
                </p>
                <span className="text-muted">TruTrace FlexiCart</span>
                <h3 className="mt-auto">
                  <small className="small"></small>
                </h3>
              </ProductCard>
            </div>
            <div className="col-md">
              <ProductCard
                className="shadow-sm"
                img={emg005}
                tall
                variant="white"
                action
              >
                <h3 className="mt-auto">
                  <small className="small"></small>
                </h3>
                <p className="mt-auto">
                  Neurophysiology
                  <br />
                  Deymed
                </p>
                <span className="text-muted mb-5">TruTrace Traveller</span>
              </ProductCard>
            </div>
          </div>
        </div>
      </div>

      <FeaturedConsumables />
    </>
  );
};
