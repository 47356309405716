import { angiomed001, angiomed002, angiomed003, angiomed006, angiomed007, angiomed008, angiomed009, decurve001, decurve002, decurve003, decurve004, defit001, defit002, defit003, defit004, defit005, emg001, emg002, emg003, emg004, emg005, emg006, emg007, emg008, emg009, emg010, emg011, emg012, emg013, neuro001, neuro002, neuro003, placeholder, psg001, psg002, psg003, psg004, psg005 } from "./assets/imagePaths";

export const ProductsData = [
   //NEUROLOGY PRODUCTS
   //1. EEG PRODUCTS
   {
      id: 1,
      name: 'TruScan LT 32ch',
      img: angiomed007,
      category: 'neurology',
      type: 'eeg',
      summary: "Numbers of single electrode connectors depends on headbox, 1x Easy connect Cap connector, Battery operated - removable batteries, Optional Holter recording to SD card, Compact dimensions 90 x 47 x 140 mm, In Connected or Ambulatory mode, the TruScan LT system can record up to 45 hours and TruScan RS up to 90 hours on a single charge.",
   },
   {
      id: 2,
      name: 'TruScan LT 64ch',
      img: angiomed008,
      category: 'neurology',
      type: 'eeg',
      summary: "Numbers of single electrode connectors depends on headbox, 1x Easy connect Cap connector, Battery operated - removable batteries, Optional Holter recording to SD card, Compact dimensions 90 x 47 x 140 mm, In Connected or Ambulatory mode, the TruScan LT system can record up to 45 hours and TruScan RS up to 90 hours on a single charge.",
   },
   {
      id: 3,
      name: 'TruScan LT 24ch',
      img: angiomed009,
      category: 'neurology',
      type: 'egg',
      summary: "Numbers of single electrode connectors depends on headbox, 1x Easy connect Cap connector, Battery operated - removable batteries, Optional Holter recording to SD card, Compact dimensions 90 x 47 x 140 mm, In Connected or Ambulatory mode, the TruScan LT system can record up to 45 hours and TruScan RS up to 90 hours on a single charge.",
   },
   {
      id: 4,
      name: 'TruScan RS 32ch',
      img: angiomed006,
      category: 'neurology',
      type: 'eeg',
      summary: "Numbers of single electrode connectors depends on headbox, 1x Easy connect Cap connector, Battery operated - removable batteries, Optional Holter recording to SD card, Compact dimensions 90 x 47 x 140 mm, In Connected or Ambulatory mode, the TruScan LT system can record up to 45 hours and TruScan RS up to 90 hours on a single charge.",
   },
   {
      id: 5,
      name: 'FlexiCart LTM',
      img: angiomed002,
      category: 'neurology',
      type: 'eeg',
      summary: "Includes FlexiCart with integrated computer and photostim, with 24ch, 32ch or 2x32ch EEG headboxes connected to a USB adapter and two HD cameras and infra-red lamp.",
   },
   {
      id: 6,
      name: 'FlexiCart with Satellite Trolley-photo-stim',
      img: angiomed001,
      category: 'neurology',
      type: 'eeg',
      summary: 'Includes computer with FlexiTrolley, Satellite Trolley with photo-stim, 24ch, 32ch or 2x32ch EEG headboxes connected to a USB adapter.',
   },
   {
      id: 7,
      name: 'FlexiCart w/photo-stim',
      img: angiomed003,
      category: 'neurology',
      type: 'eeg',
      summary: 'Includes FlexiCart with silent computer and photo-stim, with 24ch, 32ch or 2x32ch EEG headboxes connected to a USB adapter.',
   },
   {
      id: 8,
      name: 'Ambulatory / Portable',
      img: placeholder,
      category: 'neurology',
      type: 'eeg',
      summary: 'Includes laptop with 24ch, 32ch or 2x32ch EEG headboxes connected to the USB adapter.',
   },
   //2. EMG PRODUCTS
   {
      id: 9,
      name: 'TruTrace EMG 4ch Headbox',
      img: emg001,
      category: 'neurology',
      type: 'emg',
      summary: "The most common option with 4ch to facilitate an array of EMG investigations.",
   },
   // {
   //    name: 'EMG 2',
   //    img: emg002,
   //    category: 'neurology',
   //    type: 'emg',
   //    summary: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
   // },
   // {
   //    name: 'EMG 3',
   //    img: emg003,
   //    category: 'neurology',
   //    type: 'emg',
   //    summary: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
   // },
   {
      id: 10,
      name: 'TruTrace FlexiCart',
      img: emg004,
      category: 'neurology',
      type: 'emg',
      summary: "FlexiCart with integrated computer and up to two Amplifier or Stimulator Headboxes."
   },
   {
      id: 11,
      name: 'TruTrace Traveler',
      img: emg005,
      category: 'neurology',
      type: 'emg',
      summary: "Laptop and Traveler with click-and-go function for Amplifier and Stimulator Headboxes."
   },
   {
      id: 12,
      name: 'TruTrace Traveler',
      img: emg006,
      category: 'neurology',
      type: 'emg',
      summary: "The TruTrace Traveler can be a stand-alone system or as an accessory to your Hospital Cart con_guration due to the Click-and-Go portability of Deymed's battery powered headboxes"
   },
   {
      id: 13,
      name: 'TruTrace EMG 2ch Headbox',
      img: emg007,
      category: 'neurology',
      type: 'emg',
      summary: "The most affordable for standard NCS tests."
   },
   // {
   //    name: 'EMG 8',
   //    img: emg008,
   //    category: 'neurology',
   //    type: 'emg',
   //    summary: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
   // },
   {
      id: 14,
      name: 'TruTrace EMG 8ch Headbox',
      img: emg009,
      category: 'neurology',
      type: 'emg',
      summary: "More flexible solution. Includes 3 x 5pin DIN connector and 8 x individual Active and Reference 1.5 mm. Touch-Proof connector."
   },
   {
      id: 15,
      name: 'TruTrace EMG 4ch, 8ch EP Headbox',
      img: emg010,
      category: 'neurology',
      type: 'emg',
      summary: "Designed especially for EP examination. Hardware interchangeable reference and active electrodes for simple configuration. "
   },
   {
      id: 16,
      name: 'Audio Stimulator',
      img: emg011,
      category: 'neurology',
      type: 'emg',
      summary: "Click, Tone or Pop and programmable audio stimulator. To connect variety of headsets (including in-ear)."
   },
   {
      id: 17,
      name: 'Electro Stimulator',
      img: emg012,
      category: 'neurology',
      type: 'emg',
      summary: "Compatible with variety of stimulation electrodes including Deymed HandyStim for in-hand programmable controls."
   },
   {
      id: 18,
      name: 'Dual-Electro Stimulator',
      img: emg013,
      category: 'neurology',
      type: 'emg',
      summary: "For configuring advance protocols or convenience of using multiple stimulation positions without need of reconnection."
   },
   //3. PSG PRODUCTS
   {
      id: 19,
      name: 'SomniPro LT Wireless',
      img: psg001,
      category: 'neurology',
      type: 'psg',
      summary: "SomniPro LT is designed for cart use when the headbox is connected to a cart - thanks to the channels located on the top of the headbox."
   },
   {
      id: 20,
      name: 'SomniPro RS Wireless',
      img: psg002,
      category: 'neurology',
      type: 'psg',
      summary: "Somnipro RS is designed more for portable use. Thanks to the channels located on the side of the headbox, the electrodes can be comfortably connected when the headbox is placed on or beside a patient's body."
   },
   {
      id: 21,
      name: 'Ambulatory/ Portable PSG',
      img: psg003,
      category: 'neurology',
      type: 'psg',
      summary: "Includes laptop with 24ch, 32ch or 2x32ch EEG headboxes connected to the USB adapter."
   },
   {
      id: 22,
      name: 'FlexiCart PSG Video',
      img: psg004,
      category: 'neurology',
      type: 'psg',
      summary: "FlexiCart with integrated computer and one PSG headbox connected via USB adapter along with dual video monitoring and infra-red lamp."
   },
   {
      id: 23,
      name: 'FlexiTrolley and PSG',
      img: psg005,
      category: 'neurology',
      type: 'psg',
      summary: "Computer and FlexiTrolley with one PSG headbox connected via USB adapter and camera wall mount kit for dual camera."
   },
   //4. NEURO PRODUCTS
   {
      id: 24,
      name: 'Neuro 1',
      img: neuro001,
      category: 'neurology',
      type: 'neuro',
      summary: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
   },
   {
      id: 25,
      name: 'Neuro 2',
      img: neuro002,
      category: 'neurology',
      type: 'neuro',
      summary: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
   },
   {
      id: 26,
      name: 'Neuro 3',
      img: neuro003,
      category: 'neurology',
      type: 'neuro',
      summary: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged",
   },
   //DEFIT SHUNTS
   {
      id: 27,
      name: 'Standard Defit Shunt Kit',
      img: defit001,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Defit Shunt",
      summary: ""
   },
   {
      id: 28,
      name: 'Antibiotic Impregnated Defit Shunt',
      img: defit002,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Defit Shunt",
      summary: ""
   },
   {
      id: 29,
      name: 'Regular Defit Shunt',
      img: defit001,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Defit Shunt",
      summary: ""
   },
   {
      id: 30,
      name: 'Standard Defit Shunt Kit',
      img: defit003,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Defit Shunt",
      summary: ""
   },
   {
      id: 31,
      name: 'Antibiotic Impregnated Defit Shunt Kit',
      img: defit004,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Defit Shunt",
      summary: ""
   },
   {
      id: 32,
      name: 'Fully Antibiotic Impregnated Defit Shunt Kit',
      img: defit005,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Defit Shunt",
      summary: ""
   },
   //DECURVE SHUNTS
   {
      id: 33,
      name: 'Decurve Shunt',
      img: decurve001,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Decurve Shunt",
      summary: ""
   },
   {
      id: 34,
      name: 'Antibiotic Impregnated Decurve Shunt',
      img: decurve002,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Decurve Shunt",
      summary: ""
   },
   {
      id: 35,
      name: 'Standard Decurve Shunt Kit',
      img: decurve003,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Decurve Shunt",
      summary: ""
   },
   {
      id: 36,
      name: 'Fully Antibiotic Impregnated Decurve Shunt Kit',
      img: decurve004,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Decurve Shunt",
      summary: ""
   },
   //DEPUS SHUNTS
   {
      id: 37,
      name: 'Depus Shunt',
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: ""
   },
   {
      id: 38,
      name: 'Adjustable Depus Shunt',
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: ""
   },
   {
      id: 39,
      name: 'Depus Shunt with Flat-Bottom Reservoir',
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: ""
   },
   {
      id: 40,
      name: 'Depus Shunt with Burr-Hole Reservoir',
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: ""
   },
   {
      id: 41,
      name: 'Standard Depus Shunt Kit',
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: "Ventricular Catheter with Stylet and Right Angled Connector"
   },
   {
      id: 42,
      name: 'Semi Antibiotic Depus Shunt Kit',
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: "Antibiotic Impregnated Ventricular Catheter with Stylet and Right Angled Connector"
   },
   {
      id: 43,
      name: "Standard Depus Shunt Kit",
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: "Depus Shunt with Flat Bottom Reservoir"
   },
   {
      id: 44,
      name: "Semi Antibiotic Depus Shunt Kit",
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: "Depus Shunt with Flat Bottom Reservoir"
   },
   {
      id: 45,
      name: "Adjustable Ventriculoperitoneal Shunt Kits",
      img: placeholder,
      category: 'neurosurgery',
      type: 'shunting-system',
      shunt_type: "Depus Shunt",
      summary: "Depus Shunt with Flat Bottom Reservoir"
   },
   {
      id: 46,
      name: "External Ventricular Drainage System With Ventricular Catheter",
      img: placeholder,
      category: 'neurosurgery',
      type: "evd-system",
      summary: "Barium Impregnated External Ventricular Drainage Catheter and Drainage System are combined to each other.",
   },
   {
      id: 47,
      name: "External Ventricular Drainage System With Antibiotic Impregnated Ventricular Catheter",
      img: placeholder,
      category: "neurosurgery",
      type: "evd-system",
      summary: "Antibiotic Impregnated External Ventricular Drainage Catheter and Drainage System are combined to each other"
   },
   {
      id: 48,
      name: "External Lumbar Drainage System With Lumbar Catheter",
      img: placeholder,
      category: "neurosurgery",
      type: "evd-system",
      summary: "Barium Impregnated External Lumbar Drainage Catheter and Drainage System are combined to each other."
   },
   {
      id: 49,
      name: "External Lumbar Drainage System With Antibiotic Impregnated Lumbar Catheter",
      img: placeholder,
      category: "neurosurgery",
      type: "evd-system",
      summary: "Barium Impregnated External Lumbar Drainage Catheter and Drainage System are combined to each other."
   },



]

//Paginated amount of products
export const getProducts = function (page, products) {
   let array = [];
   let limit = 5 //Amount Of Products Displayed Per Page
   for (let i = (page - 1) * limit; i < page * limit && products[i]; i++) {
      array.push(products[i])
   }
   return array
}

//GET LENGTH OF PRODUCTS
export const getLength = function () {
   return ProductsData.length
}