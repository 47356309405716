import { NavContainer} from "../components/Navbar"
import { ProductsData, getLength, getProducts } from "../productData";
import { useState } from "react";
import { Pagination } from "../components/Pagination";
import { ProductCard } from "../components/ProductCard";
import { Button } from '../components/Button';
import { BsChevronLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";


export const Products = props => {
    
    //Navigation
    const navigate = useNavigate()
    //PAGINATION
    const [page, setPage] = useState(1);
    let totalPage = Math.ceil(getLength() / 5)
    function handlePageChange(value) {
        if (value === 'next') {
            setPage(page + 1)
        } else if (value === 'prev') {
            setPage(page - 1)
        }
        else {
            setPage(value)
        }
    }

    return (
        <div>
            <NavContainer>
                <span className='text-muted fs-6'>Home / Products</span>
            </NavContainer>
            <div className="container pb-5">
            <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1 mt-2`} onClick={() => navigate(-1)}/>
                <h3 className="my-3">All Products</h3>
                {getProducts(page, ProductsData).map(product => {
                    return <ProductCard key={product.name} img={product.img} product_name={product.name} summary={product.summary} />
                })}
                <Pagination totalPage={totalPage} page={page} siblings={1} onPageChange={handlePageChange} />
            </div>
        </div>
    )
}

