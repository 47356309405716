import { BsChat, BsChevronLeft, BsClock, BsEnvelope, BsFacebook, BsInstagram, BsLinkedin, BsMap, BsMessenger, BsPerson, BsPhone, BsSearch } from "react-icons/bs"
import { NavContainer, Navbar } from "../components/Navbar"
import TopCover from '../components/TopCover'
import { splash } from "../assets/imagePaths"
import { useRef } from "react"
import emailjs from '@emailjs/browser';
import { Button } from '../components/Button';
import { useNavigate } from "react-router-dom"

const InputType = ({type, label, name, icon}) => {
    return (
        <div class="input-group mb-3">
                    <input type={type} class="form-control border-0 bg-light line-only-input" name={name} placeholder={name} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <span class="input-group-text border-0 bg-light text-primary line-only-addon" id="basic-addon2">{icon}</span>
        </div>
    )
}
export const Contact = props => {

    const form = useRef();
    const navigate = useNavigate();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div>
            
            <TopCover splash={splash} title="Contact us" subtitle="HOME | CONTACT US"/>

            <div className="container py-5">
            <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1 mt-2`} onClick={() => navigate(-1)}/>
                 <div className="row d-flex justify-content-md-between">
                    <form ref={form} onSubmit={sendEmail} >
                    <div className="col-sm-12 col-md-5 py-4 pe-5">
                        <p className="">Feel free to contact us any time. We will get back to you as soon as we can</p>
                        <InputType type="text" name="Name" icon={<BsPerson/>}/>
                        <InputType type="email" name="Email" icon={<BsEnvelope/>}/>
                        <div class="input-group mb-3">
                    <textarea type="text" class="form-control border-0 bg-light line-only-input" name="Message" placeholder="Message" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <span class="input-group-text border-0 bg-light text-primary line-only-addon" id="basic-addon2">{<BsChat/>}</span>
        </div>

                        <button type='submit' className="btn btn-dark btn-block">Send</button>
                    </div>
                    </form>
                    <div className="text-light col-sm-12 col-md-5 bg-dark p-4">
                            <h4 className="mb-5">Info</h4>
                            <div className="mb-4">
                                <BsEnvelope className="me-2"/> info@angiomed.co.za
                            </div>
                            <div className="mb-4">
                                <BsPhone className="me-2"/> +27 10 900 4533
                            </div>
                            <div className="mb-4">
                                <BsMap className="me-2"/> Willlow Wood Office Park, Broadacres 2021
                            </div>
                            <div className="mb-5">
                                <BsClock className="me-3"/> 08:00 - 17:00 / Mon - Fri
                            </div>


                            <BsFacebook className="me-3"/><BsLinkedin className="me-3"/><BsInstagram className="me-3"/>
                    </div>
                 </div>
            </div>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14331.987149315999!2d28.0537253!3d-26.09902355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95732f2262e50d%3A0x4feeaa4637e8208d!2sSandton%20Physiotherapist!5e0!3m2!1sen!2sza!4v1687785277182!5m2!1sen!2sza" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
    )
}