//ALL IMAGES EXPORTABLE WITHIN THIS FILE

export const linearCutter = require("../assets/imgs/linear_cutter.png");
export const skinClosure = require("../assets/imgs/SkinClosure.png");
export const internalPng = require("../assets/imgs/internal.png");
export const pressure = require("../assets/imgs/pressure.png");
export const drainage = require("../assets/imgs/drainage.png");
export const GuReaterHD = require("../assets/imgs/GuReaterHD.png");
export const splash = require('../assets/imgs/cardio.jpeg');
export const blog_1 = require('./imgs/blog_1.jpeg');
export const gis = require('./imgs/gis.jpeg');
export const neuro = require('./imgs/neuro.jpeg')
export const neuro_surgery = require('./imgs/neurosurgery-2108.jpg');
export const neuro_vascular = require('./imgs/neurovascular.webp');
export const placeholder = require('./imgs/placeholder-2.png')

export const angiomed000 = require("../assets/angiomed/angiomed000.png");
export const angiomed001 = require("../assets/angiomed/angiomed001.png");
export const angiomed002 = require("../assets/angiomed/angiomed002.png");
export const angiomed003 = require("../assets/angiomed/angiomed003.png");
export const angiomed004 = require("../assets/angiomed/angiomed004.png");
export const angiomed005 = require("../assets/angiomed/angiomed005.png");
export const angiomed006 = require("../assets/angiomed/angiomed006.png");
export const angiomed007 = require("../assets/angiomed/angiomed007.png");
export const angiomed008 = require("../assets/angiomed/angiomed008.png");
export const angiomed009 = require("../assets/angiomed/angiomed009.png");
export const angiomed010 = require("../assets/angiomed/angiomed010.png");

export const emg001 = require("../assets/angiomed/emg001.png");
export const emg002 = require("../assets/angiomed/emg002.png");
export const emg003 = require("../assets/angiomed/emg003.png");
export const emg004 = require("../assets/angiomed/emg004.png");
export const emg005 = require("../assets/angiomed/emg005.png");
export const emg006 = require("../assets/angiomed/emg006.png");
export const emg007 = require("../assets/angiomed/emg007.png");
export const emg008 = require("../assets/angiomed/emg008.png");
export const emg009 = require("../assets/angiomed/emg009.png");
export const emg010 = require("../assets/angiomed/emg010.png");
export const emg011 = require("../assets/angiomed/emg011.png");
export const emg012 = require("../assets/angiomed/emg012.png");
export const emg013 = require("../assets/angiomed/emg013.png");

export const psg001 = require("../assets/angiomed/psg001.png");
export const psg002 = require("../assets/angiomed/psg002.png");
export const psg003 = require("../assets/angiomed/psg003.png");
export const psg004 = require("../assets/angiomed/psg004.png");
export const psg005 = require("../assets/angiomed/psg005.png");

export const neuro001 = require("../assets/angiomed/neuro001.png");
export const neuro002 = require("../assets/angiomed/neuro002.png");
export const neuro003 = require("../assets/angiomed/neuro003.png");
export const neuro004 = require("../assets/angiomed/neuro004.png");
export const neuro005 = require("../assets/angiomed/neuro005.png");
export const neuro006 = require("../assets/angiomed/neuro006.png");
export const neuro007 = require("../assets/angiomed/neuro007.png");
export const neuro008 = require("../assets/angiomed/neuro008.png");
export const neuro009 = require("../assets/angiomed/neuro009.png");
export const neuro010 = require("../assets/angiomed/neuro010.png");
export const neuro011 = require("../assets/angiomed/neuro011.png");
export const neuro012 = require("../assets/angiomed/neuro012.png");
export const neuro013 = require("../assets/angiomed/neuro013.png");
export const neuro014 = require("../assets/angiomed/neuro014.png");
export const neuro015 = require("../assets/angiomed/neuro015.png");
export const neuro016 = require("../assets/angiomed/neuro016.png");

export const evo001 = require("../assets/angiomed/evo001.png");
export const evo002 = require("../assets/angiomed/evo002.png");
export const evo003 = require("../assets/angiomed/evo003.png");

// SHUNTS
// DEFIT SHUNTS
export const defit001 = require('./imgs/Shunts/Defit_shunts/Standard_Defit_Shunt Kit.png')
export const defit002 = require('./imgs/Shunts/Defit_shunts/Antibiotic_Impregnated_Defit_Shunt.png')
export const defit003 = require('./imgs/Shunts/Defit_shunts/Standard_defit_shunt_kit_1.png')
export const defit004 = require('./imgs/Shunts/Defit_shunts/Antibiotic_Impregnated_Defit_Shunt_Kit.png')
export const defit005 = require('./imgs/Shunts/Defit_shunts/Fully_Antibiotic_Impregnated_Defit_Shunt_Kit.png')

//DECURVE SHUNTS
export const decurve001 = require('./imgs/Shunts/decurve_shunts/decurve_shunt.png')
export const decurve002 = require('./imgs/Shunts/decurve_shunts/Antibiotic_Impregnated_Decurve_Shunt.png')
export const decurve003 = require('./imgs/Shunts/decurve_shunts/Standard_Decurve_Shunt_Kit.png')
export const decurve004 = require('./imgs/Shunts/decurve_shunts/Fully_Antibiotic_Impregnated_Decurve_Shunt_Kit.png')

