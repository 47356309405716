const TopCover = ({ title, subtitle, splash }) => {
    return (
        <div class="card text-bg-dark rounded-0 border-0" style={{ height: "20vh", overflow: "hidden"}}>
            <img src={splash} class="card-img" alt="..." />
            <div class="card-img-overlay bg-dark op-1">
            </div>
            <div class="card-img-overlay">
                <div className='h-100 container d-flex flex-column justify-content-around'>

                <h5 class="text-center display-6">
                    {title}
                </h5>
                <p className="text-center">{subtitle}</p>

                </div>

            </div>

        </div>
    )
}
export default TopCover;