import { BsArrowLeft, BsArrowRight } from "react-icons/bs"
import { getPaginationRange } from "./HelperFunctions"

export const Pagination = ({ totalPage, page, siblings, onPageChange }) => {
    let array = getPaginationRange(totalPage, page, siblings, onPageChange)
    return (
    <nav aria-label="Page navigation example" className="my-5">
    <ul className="pagination">
        <li className="page-item" onClick={() => onPageChange('prev')}>
        <a className="page-link border-0" href="#" aria-label="Previous" id="prev">
        <BsArrowLeft/>
        </a>
        </li>
        {array.map(value => {
            if(value === page){
                return <li key={value} className="page-item active" onClick={() => onPageChange(value)}><a className="page-link border-0" href="#">{value}</a></li>
            } else {
                return <li key={value} className="page-item" onClick={() => onPageChange(value)}><a className="page-link border-0" href="#">{value}</a></li>
            }

        })}
        <li className="page-item" onClick={() => onPageChange('next')}>
        <a className="page-link border-0" href="#" aria-label="Next" id="next">
            <BsArrowRight />
        </a>
        </li>
    </ul>
</nav>
    )
}