import { NavContainer, Navbar } from "../components/Navbar"
import { ProductsData, getProducts } from "../productData";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "../components/HelperFunctions"
import { useState } from "react";
import { Pagination } from "../components/Pagination";
import { ProductCard } from "../components/ProductCard";
import { BsEmojiDizzy } from "react-icons/bs";
import { Button } from '../components/Button';
import { BsChevronLeft } from "react-icons/bs";



export const CategorizedProducts = props => {
    //CATEGORIZED BY NEUROSURGERY, Neurology, Neurovascular, GIS 

    // DECLARATIONS
    let { category } = useParams();
    const [page, setPage] = useState(1);
    let filteredProducts = null;
    const navigate = useNavigate()



    //FILTER PRODUCTS BY CATEGORY
    if (category === 'neurosurgery') {
        filteredProducts = ProductsData.filter(products => products.category === 'neurosurgery')
    } else if (category === 'neurology') {
        filteredProducts = ProductsData.filter(products => products.category === 'neurology')

    } else if (category === 'neurovascular') {
        filteredProducts = ProductsData.filter(products => products.category === 'neurovascular')
        console.log(filteredProducts)
    } else if (category === 'gis') {
        filteredProducts = ProductsData.filter(products => products.category === 'gis')
    }
    else {
        filteredProducts = []
        category = null
    }


    //RETURN STATEMENT
    if (category) {
        let category_name = capitalizeFirstLetter(category)
        if (filteredProducts.length !== 0) {
            // PAGINATION
            let totalPage = Math.ceil(filteredProducts.length / 5)
            function handlePageChange(value) {
                if (value === 'next') {
                    setPage(page + 1)
                } else if (value === 'prev') {
                    setPage(page - 1)
                }
                else {
                    setPage(value)
                }
            }
            return (
                <div>

                    <NavContainer>
                        <span className='text-muted fs-6'>Home / Products / {category_name} </span>
                    </NavContainer>


                    <div className="container pb-5">
                    <Button icon={<BsChevronLeft />} variant={'outline-secondary'} className={`rounded-icon mx-1 mt-2`} onClick={() => navigate(-1)}/>
                        <h3 className="my-3">{category_name} Products</h3>

                        {
                            getProducts(page, filteredProducts).map(product => {
                                console.log(category_name)
                                return <ProductCard key={product.id} img={product.img} product_name={product.name} summary={product.summary} />
                            })
                        }
                        <Pagination totalPage={totalPage} page={page} siblings={1} onPageChange={handlePageChange} />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
    
                    <NavContainer>
                        <span className='text-muted fs-6'>Home / Products  </span>
                    </NavContainer>
    
                    <div className="container pb-5">
                        <h3 className="my-3"> Products</h3>
                        <div className="text-danger">
           <BsEmojiDizzy size={40}/>
           <span className="p-3 text-muted fs-2">Sorry, Nothing Here Yet.</span>
          </div>
                    </div>
                </div>
            )
        }
    }

}