import logo from './logo.png';
import './App.css';
import { Button } from './components/Button';
import { BrowserRouter, createBrowserRouter, Route, RouterProvider, Routes, useNavigate } from 'react-router-dom';
import { NavContainer, NavFormInline, NavNalist, Navbar } from './components/Navbar';
import { BsAirplane } from 'react-icons/bs';
import { Layout } from './pages/Layout';
import { Home } from './pages/Home';
import { Products } from './pages/Products';
import { CategorizedTypeProducts } from './pages/CategorizedTypeProducts';
import { CategorizedProducts } from './pages/CategorizedProducts';
import { Contact } from './pages/Contact';
import About from './pages/About';
import Services from './pages/Services';
import { Featured, FullFeatured } from './pages/Featured';
import FeaturedConsumables from './components/FeaturedConsumables';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/products",
    element: <Products />
  },
  {
    path: "/products/:category",
    element: <CategorizedProducts/>
  }
])

function App() {
  return (
    <>
<div className='container'>
      <div className="py-2 justify-content-between align-items-center">
      <div className='row'>
        <div className='col-sm-12 col-md-4 text-center pt-3'>
      <img src={logo} className="logo-top" alt="logo" />
        </div>
        <div className='col-sm-12 col-md-4 pt-3'>
      <div className='text-muted text-center'>Willlow Wood Office Park, Broadacres 2021</div>
      <div className='text-center'>+27 10 900 4533</div>
      <div className='text-muted text-center'>info@angiomed.co.za</div>
        </div>
        <div className='col-sm-12 col-md-4 text-center my-auto py-2 py-sm-0'>
      <Button variant="outline-primary" rounded size="sm" className="px-4" title="Contact angiomed" onClick={() => {
           window.location.href = "/contact"
      }}/>
        </div>
      </div>
        </div>
        </div>
   


      <BrowserRouter>
         <Routes>
          <Route path='/' element={<Layout />}>
              <Route index element={<Home />}></Route>
              <Route path='/products' element={<Products />}></Route>
              <Route path='/services' element={<Services />}></Route>
              <Route path='/contact' element={<Contact />}></Route>
              <Route path='/about' element={<About />}></Route>
              <Route path='/products/:category' element={<CategorizedProducts/>}></Route>
              <Route path='/products/:category/:type' element={<CategorizedTypeProducts/>}></Route>
              <Route path='/featured' element={<FeaturedConsumables />}></Route>
              <Route path='/featured/:article' element={<FullFeatured />}></Route>
          </Route>
         </Routes>
      </BrowserRouter>
      
      


      </>
  );
}

export default App;
